// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'core-js/stable'
import 'regenerator-runtime/runtime'

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// For cocoon
require("jquery")
require("@nathanvda/cocoon")

require("popper.js")
require("bootstrap")

import "../stylesheets/application.scss"

// import 'bootstrap'

// require.context('../images', true)


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

document.addEventListener("turbolinks:load", () => {
    function toggleSidebar() {
        $(".sidebar").toggleClass("toggled");
        ($(".sidebar").hasClass("toggled"))?
            $('.nav-item.active .collapse').removeClass('show'):
            $('.nav-item.active .collapse').addClass('show');
    }
    let sidebarToggle = document.getElementById('sidebarToggle');
    let sidebarToggleTop = document.getElementById('sidebarToggleTop');

    $(document).ready(function () {
        $('.datepicker').datepicker({
            format: 'dd/mm/yyyy',
            todayBtn: "linked",
            todayHighlight: true,
            autoclose: true
        });

        // Set responded to true when feedback responded button was clicked
        $('.respond-btn').on('click', function() {
            let form_query_form = $(this).parents('form.edit_form_query');
            $(form_query_form).find('input[name="form_query[responded]"]').val(true);
        })

        // Hide search query modal comments after closing
        $('.resolved-btn').on('click', function () {
            let form_query_form = $(this).parents('form.edit_form_query');
            $(form_query_form).find('input[name="form_query[resolved]"]').val(true);
        })

        $('.unresolved-btn').on('click', function () {
            let form_query_form = $(this).parents('form.edit_form_query');
            $(form_query_form).find('input[name="form_query[resolved]"]').val(false);
        })

        sidebarToggle.addEventListener('click', function() {
            toggleSidebar()
        });
        sidebarToggleTop.addEventListener('click', function() {
            toggleSidebar()
        });

        // Save complete/incomplete
        $('input.complete-btn[type="submit"]').click(function (event) {
            var confirmMessage = $(this).data('confirm');
            if(!confirmMessage) {
                event.preventDefault();
                $('input.completed-input').val(true);
                $(this).parents('form').submit();
            }
        })
        $('input.incomplete-btn[type="submit"]').click(function (event) {
            var confirmMessage = $(this).data('confirm');

            if(!confirmMessage) {
                event.preventDefault();
                $('input.completed-input').val(false);
                $(this).parents('form').submit();
            }

        })


        // Input format check when type = number

        // Set tooltips auto hide
        $(document).on('shown.bs.tooltip', function (e) {
            setTimeout(function () {
                $(e.target).tooltip('hide');
            }, 1500);
        });

        // Check input format when unfocus if input type is number
        $('#wrapper').on("focusout", "input[type='number']", function (event) {
            checkNumberInputFormat(this, event);
        });

        $('#vital-sign-measures-table').on('cocoon:after-insert', function (e, insertedItem, originalEvent) {
            $('.datepicker').datepicker({
                format: 'dd/mm/yyyy',
                todayBtn: "linked",
                todayHighlight: true,
                autoclose: true
            });
        });

        $('#conc-meds-relevant-fields').on('cocoon:after-insert', function (e, insertedItem, originalEvent) {
            insertedItem.find('.subject_conc_med_conc_meds_ae_number').hide();
            insertedItem.find('.subject_conc_med_conc_meds_stop_date').hide();
            // insertedItem.find('div.subject_conc_med_conc_meds_unit_specify').hide();
            // insertedItem.find('div.subject_conc_med_conc_meds_route_specify').hide();
            $('.datepicker').datepicker({
                format: 'dd/mm/yyyy',
                todayBtn: "linked",
                todayHighlight: true,
                autoclose: true
            });
        });
        
        $('#adverse-event-relevant-fields').on('cocoon:after-insert', function (e, insertedItem, originalEvent) {
            insertedItem.find('.subject_adverse_event_adverse_events_report_sponsor_date').hide();
            insertedItem.find('.subject_adverse_event_adverse_events_event_end_date').hide();
            insertedItem.find('.subject_adverse_event_adverse_events_event_death').hide();
            insertedItem.find('.subject_adverse_event_adverse_events_death_date').hide();
            insertedItem.find('.subject_adverse_event_adverse_events_specify_action_taken').hide();

            $('.datepicker').datepicker({
                format: 'dd/mm/yyyy',
                todayBtn: "linked",
                todayHighlight: true,
                autoclose: true
            });
        });

        function checkNumberInputFormat(input, event) {
            let numbers_format = new RegExp('^[+-]?[0-9]+\.?[0-9]*$');
            let value = $(input).val();
            if (numbers_format.test(value)) {
                $(input).tooltip('hide');
            } else {
                $(input).tooltip({
                    title: 'Numbers only',
                    placement: 'top',
                    trigger: 'manual'
                }).tooltip('show');
            }
        }
        // End Input format check when type = number

        // Assessment hint appear when Abnormal CS was chosen
        const assessmentInputs = document.querySelectorAll('.assessment_input');

        // Initial check to set the correct display state for all hints
        assessmentInputs.forEach(input => {
            check_assessment_input_hint(input);
            input.addEventListener('change', function() {
                check_assessment_input_hint(this);
            });
        });

        function check_assessment_input_hint(input) {
            const parentFieldset = input.closest('fieldset');
            const warningText = parentFieldset.querySelector('.form-text.text-muted');

            if (input.checked && input.value === '3') {
                warningText.classList.add('red-important');
                warningText.style.display = 'block'; // Ensure it's visible if "Abnormal CS"
            } else {
                warningText.classList.remove('red-important');
                warningText.style.display = 'none'; // Hide the text otherwise
            }
        }

        // End assessment hint appear when Abnormal CS was chosen

        $('#target-lesion-relevant-fields').on('cocoon:after-insert', function (e, insertedItem, originalEvent) {
            insertedItem.find('.subject_target_lesion_target_lesions_organ').hide();
            insertedItem.find('.subject_target_lesion_target_lesions_organ_other').hide();
            insertedItem.find('.subject_target_lesion_target_lesions_nodal_area').hide();
            insertedItem.find('.subject_target_lesion_target_lesions_nodal_area_other').hide();
            insertedItem.find('.subject_target_lesion_target_lesions_skeleton').hide();
            insertedItem.find('.subject_target_lesion_target_lesions_skeleton_other').hide();

        });
    });
});